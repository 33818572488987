import React, { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ITarget } from '../../app/models/adverts/ITarget';

interface Props {
    image: string;
    title: string;
    content: string
    imageTarget: string;
    targets: ITarget[];
}

export default function AdvertOwnBlock ({image, title, content, imageTarget, targets}: Props) {
    
    return (
        <Fragment>
            <Card style={{ marginBottom: '1em' }}>
            <Link to={imageTarget}><Card.Img variant="top" src={`/assets/${image}`} /></Link>
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    {/* <Card.Text> */}
                    <div
                    dangerouslySetInnerHTML={{
                        __html: content
                    }}></div>
                    {/* </Card.Text> */}
                    {/* <Button as={Link} to='/ihre-backlinks' variant="primary"><FontAwesomeIcon icon={faLink} style={{marginRight: '0.5em'}}/>Backlinks aufbauen</Button> */}
                    {targets.length > 0 && targets.map((item: ITarget, i) => (
                       <Card.Link key={i} as={Link} to={item.linkTarget}>{item.linkText}</Card.Link>     
                    ))}
                </Card.Body>
            </Card>
        </Fragment>
    )
}