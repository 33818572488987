import React, { Fragment, useEffect, useState } from "react";
import { Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { IAdvert } from "../../app/models/adverts/IAdvert";

enum Direction {
    Up = 1,
    Down = 2,
    Left = 3,
    Right = 4,
  }

interface Props {
    advertCount: number;
    advertType: Direction;
}

export default function AdvertsBlock ({advertCount, advertType}: Props) {

    const [adverts, setAdverts] = useState<IAdvert[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        agent.Adverts.lastAdverts(advertCount, advertType).then(response => {
            // console.log('Ads', response);
            setAdverts(response);
            setIsLoading(false);
        })
    }, [advertCount, advertType])

    if (isLoading) return <LoadingComponent content='Loading...' />

    return (
        <Fragment>
            {adverts.map((advert: IAdvert, i) => 
            [
            <Card key={i} style={{ marginBottom: '1em' }}>

                    {(advert.type === 1 || advert.type === 5) && advert.target === 0 &&
                      <Link to={advert.targetUrl}><Image fluid src={`/assets/banners/${advert.image}`} /></Link>
                    }

                    {(advert.type === 1 || advert.type === 5) && advert.target === 1 &&
                      <a href={advert.targetUrl}><Image fluid src={`/assets/banners/${advert.image}`} /></a>
                    }


                {(advert.type === 2 || advert.type === 6 || advert.type === 10)
                    && advert.target === 0 &&
                    <Link to={advert.targetUrl}><Card.Img variant='top' src={`/assets/banners/${advert.image}`} /></Link>
                }

                {(advert.type === 2 || advert.type === 6 || advert.type === 10)
                    && advert.target === 1 &&
                    <a href={advert.targetUrl}><Card.Img variant='top' src={`/assets/banners/${advert.image}`} /></a>
                }
                
                {advert.type === 1 && advert.content && advert.content !== '' &&
                <Card.Body>
                    <Card.Text>{advert.content}</Card.Text>
                </Card.Body>
                }
            </Card>
            ])}
        </Fragment>
    )
}