import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <Fragment>
        <hr />
        <div style={{marginBottom: '1em'}}>
            © 2021 - kayomo GmbH | <Link to='/impressum'>Impressum</Link> | <Link to='/datenschutz'>Datenschutz</Link> | <Link to='/kontakt'>Kontakt</Link>
        </div>
        
        </Fragment>
    )
}