import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { history } from '../..';
import SearchBox from "./SearchBox";
import { useStore } from "../../app/stores/store";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { setPredicate } = blogStore;

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
        history.push("/artikel")
    }

    return (
        <Fragment>
            <Helmet>
                <title>Master Online Business</title>      
                <meta name="description" content="Das Portal zum Thema Online-Business. So bauen Sie Ihr Online-Unternehmen erfolgeich auf." />
                <meta name="keywords" content="online business, unternehmen aufbauen, erfolgreiches unternehmen, unternehmer" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Online Unternehmen erfolgreich machen</h1>
            <AdvertOwnBlock
                title='Bauen Sie Ihr erfolgreiches Online Unternehmen auf'
                content='<p>Sie haben bereits ein Online Unternehmen oder Sie planen, ein Online Unternehmen aufzubauen?</p><p>Von der Idee zur Strategie, von der Planung bis hin zur Umsetzung sind dabei viele Fragen zu beantworten. Und diese Antworten entscheiden letztlich über den Erfolg oder Mißerfolg Ihrer Online Unternehmung.</p>'
                image='/banners/master-online-business-banner.png'
                imageTarget=''
                targets={[
                    // { linkText: 'Was ist Online Marketing?', linkTarget: '/was-ist-online-marketing' },
                    // { linkText: 'Gesund und Fit durch Fasten', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            {/* <AdvertsBlock advertCount={1} advertType={1} /> */}

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />
                
                <NewsletterSubscribe />

                {/* <AmazonAffiliate
                    image='https://m.media-amazon.com/images/I/41yu7s9w+eL.jpg'
                    text='Affiliate-, Influencer-, Content- und E-Mail-Marketing, Google Ads, SEO, Social Media, Facebook-Werbung'
                    link='https://www.amazon.de/dp/B07NZ9Z2LL/ref=as_li_ss_tl?dchild=1&keywords=online+marketing&qid=1624364799&sr=8-5&linkCode=ll1&tag=kayomo-21&linkId=24a753dbab70767b9951014ac18bf2dc&language=de_DE'
                    title='Praxiswissen Online-Marketing'
                /> */}

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}