import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';

interface Props {
    postTags: string[];
}

export default function PostTags({postTags}: Props) {

    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {        
      setTags(postTags);
    },[postTags])

    return (
        <div>
            <hr />
            {tags.map((tag, i) => (
                <Badge style={{marginRight: '0.5em'}} key={i} variant="info">{tag}</Badge>
            ))}
            
        </div>
    )
}