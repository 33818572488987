import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function BacklinkProductBlock () {
  
    return (
        <Fragment>
            <Card style={{ marginBottom: '1em' }}>
                <Card.Img variant="top" src="/assets/products/erfolgreiche-webseiten-google.png" />
                <Card.Body>
                    <Card.Title>Erfolgreiche Webseiten</Card.Title>
                    <Card.Text>
                    Webseiten in Google erfolgreich machen durch den Aufbau von qualitativ hochwertigen Backlinks
                    </Card.Text>
                    <Button block as={Link} to='/ihre-backlinks' variant="primary"><FontAwesomeIcon icon={faLink} style={{marginRight: '0.5em'}}/>Backlinks aufbauen</Button>
                </Card.Body>
            </Card>
        </Fragment>
    )
}