import React, { Fragment } from 'react';
import { Button, Jumbotron } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
      <Fragment>
        <Helmet>
                <title>Gesund und Fit durch Fasten | kayomo GmbH</title>      
                <meta name="description" content="Fasten, Gesundheit und Fitness - zu unseren Artikeln " />
                <meta name="keywords" content="fasten,fitness,gesundheit" />
                <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Jumbotron>
        <h1>Ooops -<br />wir haben leider nicht gefunden, wonach Sie suchen.</h1>
        <p>
          <Button as={Link} to="/" variant="primary">Zurück zur Hauptseite</Button>
        </p>
      </Jumbotron>
      </Fragment>
    )
}