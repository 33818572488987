import axios, { AxiosError, AxiosResponse } from 'axios';
import { history } from '../..';
import { IAdvert } from '../models/adverts/IAdvert';
import { IBlogPost } from '../models/content/IBlogPost';
import { IBlogPostShort } from '../models/content/IBlogPostShort';
import { IComment } from '../models/content/IComment';
import { IPage } from '../models/content/IPage';
import { ITag } from '../models/content/ITag';
import { IContactInput } from '../models/forms/IContactInput';
import { INewsletterInput } from '../models/forms/INewsletterInput';
import { IBio } from '../models/order/IBio';
import { ICheckVatId } from '../models/order/ICheckVatId';
import { ICountry } from '../models/order/ICountry';
import { IOrderCreate } from '../models/order/IOrderCreate';
import { IOrderItem } from '../models/order/IOrderItem';
import { IOrderPost } from '../models/order/IOrderPost';
import { IOrderPostText, IOrderPostTextResult } from '../models/order/IOrderPostText';
import { ITaxCheck, ITaxInfo } from '../models/order/ITaxInfo';
import { PaginatedResult } from '../models/pagination';
import { IPayPalInfo } from '../models/payment/IPayPalInfo';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(async response => {
    const pagination = response.headers['pagination'];

    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
    
}, (error: AxiosError) => {
    const {data, status} = error.response!;
    switch (status) {
        case 400:
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key]);
                    }
                }
                throw modalStateErrors.flat();
            } else {
                // toast.error(data);
            }
            break;
        case 404:
            history.push('/not-found')                 
            break;
        case 500:
            // store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;
let headers = { 'site': process.env.REACT_APP_SITE_ID};
// let headers = { 'site': 'one'};

const requests = {
    get: <T> (url: string) => axios.get<T>(url, { headers }).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body, {headers}).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body, {headers}).then(responseBody),
    delete: <T> (url: string) => axios.delete<T>(url, {headers}).then(responseBody)
}

const Posts = {
    listPosts: (params: URLSearchParams) => axios.get<PaginatedResult<IBlogPostShort[]>>('/posts', {params, headers}).then(responseBody),
    lastPosts: (count: number) => requests.get<IBlogPostShort[]>(`/posts/getlatestposts/${count}`),
    loadPost: (slug: string) => requests.get<IBlogPost>(`/posts/${slug}`),
    loadComments: (slug: string) => requests.get<IComment[]>(`/posts/comments/${slug}`),
    loadTagSummary: (max: number) => requests.get<ITag[]>(`/posts/gettags/${max}`),
}

const OrderPosts = {
    loadOrderPost: (orderid: string, postid: string) => requests.get<IOrderPost>(`/posts/orderpost/${orderid}/${postid}`),
    updateOrderPost: (orderpost: IOrderPost) => requests.post<IOrderPost>('/posts/orderpost/', orderpost),
    checkOrderPostText: (orderposttext: IOrderPostText) => requests.post<IOrderPostTextResult>('/posts/orderpost/checktext', orderposttext),
    loadOrderBio: (orderid: string, postid: string) => requests.get<IBio>(`/posts/orderbio/${orderid}/${postid}`),
    updateOrderBio: (orderbio: IBio) => requests.post<IBio>('/posts/orderbio/', orderbio),
}

const Pages = {
    loadPage: (slug: string) => requests.get<IPage>(`/pages/${slug}`),
}

const Adverts = {
    lastAdverts: (count: number, type: number) => requests.get<IAdvert[]>(`/adds/${count}/${type}`),
}

const Forms = {
    sendContactForm: (contactForm: IContactInput) => requests.post<IContactInput>(`/contact/contact`, contactForm),
    subscribeNewsletterForm: (subscribeForm: INewsletterInput) => requests.post<number>(`/contact/subscribe`, subscribeForm),
    verifyNewsletter: (id: string) => requests.get<number>(`/contact/verify/${id}`),
}

const Orders = {
    createOrder: (orderInfo: IOrderCreate) => requests.post<IOrderCreate>(`/orders/createorder`, orderInfo),
    paypalCompleted: (payPalInfo: IPayPalInfo) => requests.post<IPayPalInfo>(`/orders/paypal-transaction-complete`, payPalInfo),
    getPDFBill: (orderid: string) => axios.get(`orders/bill/${orderid}`, {responseType: 'blob'}).then(responseBody),
    loadCountries: () => requests.get<ICountry[]>(`/orders/countries`),
    checkVatId: (vat: ICheckVatId) => requests.post<boolean>(`/orders/checkvatid`, vat),
    getTaxInfo: (tax: ITaxCheck) => requests.post<ITaxInfo>(`/orders/gettaxinfo`, tax),
    getContentDescription: () => requests.get<string>('/orders/contentdescription'),
}

const Products = {
    loadProducts: (type: number) => requests.get<IOrderItem[]>(`/products/${type}`),
}

const agent = {
    Posts,
    Pages,
    Adverts,
    Forms,
    Orders,
    OrderPosts,
    Products
}

export default agent;
