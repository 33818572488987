import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { INewsletterInput } from '../../app/models/forms/INewsletterInput';
import agent from '../../app/api/agent';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

export default function NewsletterSubscribe() {

    // const [success, setSuccess] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    var formInitial = {
        email: '',
    } as INewsletterInput;

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Bitte geben Sie eine gültige Email-Adresse ein')
            .required('Bitte geben Sie eine Email-Adresse ein')
            .max(100),
    })

    function handleFormSubmit(input: INewsletterInput) {
        // console.log('Form Newsletter abgesendet', input);
        // console.log('Form Newsletter abgesendet', JSON.stringify(input, null, 2));

        setIsLoading(true);
        try {
            agent.Forms.subscribeNewsletterForm(input).then(response => {
                // console.log(response);

                if (response === 0) {
                    // console.log('Fehler: ', response)
                    toast.error('Es ist ein Fehler aufgetreten', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        });
                }
                
                if (response === 1) {
                    // console.log('OK: ', response)
                    toast.success('Bitte prüfen Sie Ihre Emails und bestätigen Sie die Anmeldung', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        });
                }

                if (response === 10) {
                    // console.log('ALREADY: ', response)
                    toast.info('Sie sind unserem Newsletter bereits beigetreten', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        });
                }

                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    }
    
    return (
        <Fragment>
        <Card style={{marginBottom: '1em'}}
            bg={'light'}
            text={'dark'}
            >
            <Card.Header><strong>Newsletter abonnieren</strong></Card.Header>
            <Card.Body>
                <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={formInitial}
                    onSubmit={values => handleFormSubmit(values)}>
                    {({handleSubmit, isValid, isSubmitting, dirty, handleChange, errors}) => {
                            return (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId="email">
                                        {/* <Form.Label>Email</Form.Label> */}
                                        <Form.Control type="text" name="email" placeholder="Ihre Emailadresse" onChange={handleChange} isInvalid={!!errors.email} />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Button
                                        block
                                        disabled={isSubmitting || !dirty || !isValid}
                                        variant="success"
                                        type="submit">
                                        {isLoading &&
                                            <Spinner style={{ marginRight: '10px' }}
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true" />}
                                        {!isLoading && <FontAwesomeIcon icon={faSignInAlt} style={{ marginRight: '0.5em' }} />}
                                        Eintragen
                                    </Button>
                                </Form>
                            );
                        }}
                </Formik>
            </Card.Body>
        </Card>
        
        <ToastContainer pauseOnHover={false} />   
        </Fragment>
    )
}