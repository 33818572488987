import { observer } from 'mobx-react-lite';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Route, Switch, useLocation } from 'react-router-dom';
import NotFound from '../../features/errors/NotFound';
import ContactForm from '../../features/home/ContactForm';
import HomePage from '../../features/home/HomePage';
// import BacklinksOffer from '../../features/offers/BacklinksOffer';
// import BacklinkProductSelection from '../../features/orders/BacklinkProductSelection';
// import CheckOut from '../../features/orders/CheckOut';
// import ThankYou from '../../features/orders/ThankYou';
import ShowPage from '../../features/pages/ShowPage';
import ListPosts from '../../features/posts/ListPosts';
import ShowBlogPost from '../../features/posts/ShowBlogPost';
import Footer from './Footer';
import NavBar from './NavBar';
import CookieConsent from "react-cookie-consent";
// import GetPDFBill from '../../features/orders/GetPDFBill';
// import OrderPost from '../../features/orderinfo/OrderPost';
// import OrderBio from '../../features/orderinfo/OrderBio';
import NewsletterVerify from '../../features/home/NewsletterVerify';
// import AdvertInfo from '../../features/adverts/AdvertInfo';

function App() {
  const location = useLocation();
 
  return (
    <Container style={{marginTop: '5em'}} >
      <CookieConsent
        location="bottom"
        buttonText="Zustimmen"
        cookieName="kayomoCookieConsent"
        enableDeclineButton
        declineButtonText="Ablehnen"
        declineButtonStyle={{ background: 'grey', color: 'white' }}
        style={{ background: "#2B373B" }}
        buttonStyle={{ background: 'green', color: 'white' }}
        expires={150}
        onDecline={() => {
          
        }}
      >
        Diese Webseite verwendet Cookies, um Benutzereinstellungen zu speichern und um das Benutzererlebnis zu verbessern.<br />
        Zusätzlich werden technisch notwendige Informationen erhoben, um die Funktionalität und die Stabilität der Webseite zu gewährleisten.
      </CookieConsent>
      <NavBar />
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/artikel' component={ListPosts} />
          <Route exact path='/kontakt' component={ContactForm} />
          {/* <Route exact path='/ihre-werbung' component={AdvertInfo} /> */}
          {/* <Route exact path='/ihre-backlinks' component={BacklinksOffer} />
          <Route exact path='/ihre-backlinks/produktauswahl' component={BacklinkProductSelection} />
          <Route exact path='/ihre-backlinks/bestellung' component={CheckOut} />
          <Route exact path='/bestellung/vielen-dank' component={ThankYou} />
          <Route exact path='/bestellung/ihre-rechnung/:orderid' component={GetPDFBill} />
          <Route exact path='/bestellung/artikel/:orderid/:postid' component={OrderPost} />
          <Route exact path='/bestellung/bio/:orderid/:bioid' component={OrderBio} /> */}

          <Route exact path='/newsletter/verify/:id' component={NewsletterVerify} />

          {/* <Route exact path='/errors' component={TestErrors} /> */}
          <Route exact path='/not-found' component={NotFound} />
          {/* <Route exact path='/server-error' component={ServerError} />  */}

          <Route path='/artikel/:slug' component={ShowBlogPost} />
          <Route key={location.key} path='/:slug' component={ShowPage} />
          
        </Switch>
      <Footer />
    </Container>
  );
}

export default observer(App);
