import { createContext, useContext } from "react";
import BlogStore from "./blogStore";
import CheckoutStore from "./checkoutStore";

interface Store {
    blogStore: BlogStore;
    checkoutStore: CheckoutStore;
    // commonStore: CommonStore;
    // pageStore: PageStore;
    // backlinkOrderStore: BacklinkOrderStore;

    // commonStore: CommonStore;
}

export const store: Store = {
    blogStore: new BlogStore(),
    checkoutStore: new CheckoutStore(),
    // commonStore: new CommonStore()
    // pageStore: new PageStore(),
    // commonStore: new CommonStore(),
    // backlinkOrderStore: new BacklinkOrderStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}