import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { IBio } from "../../app/models/order/IBio";

interface Props {
    bio: IBio;
}

export default observer(function Bio({bio}: Props) {

    const [showEmail, setShowEmail] = useState(false);

    function toggleEmail() {
        setShowEmail(!showEmail);
    }

    return (
        <Fragment>
            <hr />
            <Card bg={'light'}>
            <Card.Header>Unternehmensinformationen zum Autor des Textes</Card.Header>
            <Card.Body>
                <Card.Title>{bio.company}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                {bio.content}
                </Card.Text>
            </Card.Body>
            <Card.Footer style={{fontSize: 'small'}}>
                {bio.url && 
                    <span style={{marginRight: '0.5em'}}><strong>Webseite:</strong> <a href={bio.url}>{bio.urlText}</a></span>
                }
                {bio.phone && 
                    <span style={{marginRight: '0.5em'}}><strong>Tel.:</strong> {bio.phone}</span>
                }

                {bio.contactEmail && showEmail &&                 
                    <span><strong>Email:</strong> <a href={`mailto:${bio.contactEmail}`}>{bio.contactEmail}</a></span>
                }

                {bio.contactEmail && !showEmail &&                 
                    <span><strong>Email:</strong> <Button size='sm' variant='success' onClick={() => toggleEmail()}>Klicken, um Email anzuzeigen</Button></span>
                }

                {bio.postal && bio.city && bio.country && 
                <Fragment>
                    <br /><span><strong>Adresse:</strong> {bio.street}, {bio.postal} {bio.city}, {bio.country}</span>
                </Fragment>
                }
            </Card.Footer>
            </Card>
        </Fragment>
    )
})