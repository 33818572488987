import React from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
    inverted?: boolean;
    content?: string;
}

export default function LoadingComponent ({inverted = true, content = 'Loading...'}: Props) {
    return (
        <Spinner animation="grow" variant="success" />
    )
}